export default {
  lineNumberColor: `#00193a`,
  lineNumberBgColor: `#eaeef3`,
  backgroundColor: `#eaeef3`,
  textColor: `#00193a`,
  substringColor: `#4c81c9`,
  keywordColor: undefined,
  attributeColor: `#4c81c9`,
  selectorAttributeColor: undefined,
  docTagColor: undefined,
  nameColor: `#0048ab`,
  builtInColor: `#0048ab`,
  literalColor: `#0048ab`,
  bulletColor: `#4c81c9`,
  codeColor: `#00193a`,
  additionColor: `#0048ab`,
  regexpColor: `#4c81c9`,
  symbolColor: `#4c81c9`,
  variableColor: `#4c81c9`,
  templateVariableColor: `#4c81c9`,
  linkColor: `#4c81c9`,
  selectorClassColor: `#0048ab`,
  typeColor: `#0048ab`,
  stringColor: `#0048ab`,
  selectorIdColor: `#0048ab`,
  quoteColor: `#0048ab`,
  templateTagColor: `#00193a`,
  deletionColor: `#4c81c9`,
  titleColor: `#0048ab`,
  sectionColor: `#0048ab`,
  commentColor: `#738191`,
  metaKeywordColor: `#00193a`,
  metaColor: `#4c81c9`,
  functionColor: `#00193a`,
  numberColor: `#00193a`,
}
